import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

import moment from 'moment';

const config = {
    apiKey: "AIzaSyAoYpHkE7AGsJcx3rv7lyaMAP9TsC5nCtY",
    authDomain: "proyecto-monuu.firebaseapp.com",
    databaseURL: "https://proyecto-monuu.firebaseio.com",
    projectId: "proyecto-monuu",
    storageBucket: "proyecto-monuu.appspot.com",
    messagingSenderId: "510562116602",
    appId: "1:510562116602:web:051208d0e9997052"
}

class Firebase {
	constructor() {
		app.initializeApp(config)
		this.auth = app.auth()
        this.firestore = app.firestore()
        this.storage = app.storage()
	}

	login(email, password) {
		return this.auth.signInWithEmailAndPassword(email, password)
	}

	logout() {
		return this.auth.signOut()
	}

	async register(name, lastName, email, password) {
        const timestamp = moment().valueOf()
		await this.auth.createUserWithEmailAndPassword(email, password)
		return this.auth.currentUser.updateProfile({
			displayName: name
		}).then(() => {
            return this.firestore.doc(`users/${this.auth.currentUser.uid}`).set({
                name,
                lastName,
                id: this.auth.currentUser.uid,
                isActive: false,
                email,
                password,
                timestamp
            })
        })
    }

    userValidator(id) {
        return new Promise(resolve => {
            this.firestore.doc(`users/${id}`).onSnapshot(snap => {
                const user = snap.data();
                resolve(user.isActive)
            });
        });
    }

	isInitialized() {
		return new Promise(resolve => {
			this.auth.onAuthStateChanged(resolve)
		})
    }
    
    getUser(id) {
        return this.firestore.collection('users').doc(id)
    }

	completeAccount(birthday,about,street,inNumber,town,zipCode,proof,officialId) {
        if (birthday && about && street && town && zipCode && proof && officialId) {
            const uploadFile = (file,type) => {
                return new Promise((resolve,reject) => {
                    const ext = file.name.split('.').pop()
                    const fileName = `${this.auth.currentUser.uid}-${type}.${ext}`;
                    const uploadFile = this.storage.ref(`users/${this.auth.currentUser.uid}`).child(fileName).put(file);
                    uploadFile.on('state_changed', snapshot => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        return progress
                    }, error => {
                        reject(error)
                    }, () => {
                        uploadFile.snapshot.ref.getDownloadURL().then(url => {
                            resolve(url)
                        })
                    });
                })
            }
            return uploadFile(proof,'proof').then(proofUrl => {
                uploadFile(officialId,'officialId').then(officialIdUrl => {
                    this.firestore.doc(`users/${this.auth.currentUser.uid}`).update({
                        birthday,about,street,inNumber,town,zipCode,proof:proofUrl,officialId:officialIdUrl,isActive: 'pending', balance: 0
                    })
                })
            })
        } else {
            alert('Completa todos los campos para continuar')
        }
    }

    getHelpList() {
        const helps = this.firestore.collection('help')
        return helps
    }

    sendMessage(name,email,message) {
        const ref = this.firestore.collection('messages').doc()
        const item = {
            name,
            email,
            message,
            id: ref.id,
            timestamp: moment().valueOf(),
            isRead: false
        }
        return ref.set(item).then(() => {
            alert('Un asesor MONUU se pondrá en contacto contigo')
        }).catch(error => {
            alert(error.message)
        })
    }
    suscribe(item) {
        const ref = this.firestore.collection('subscribes').doc()
        item.timestamp = moment().valueOf()
        item.id = ref.id
        return ref.set(item)
    }

    getCollection(col) {
        return this.firestore.collection(col)
    }
    getDocument(col,doc) {
        return this.firestore.collection(col).doc(doc)
    }

    async simpleAdd(item,col) {
        const ref = this.firestore.collection(col).doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        await ref.set(item);
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
        };
        return movesRef.set(move);
    }
}

export default new Firebase()