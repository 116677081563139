import React from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import moment from 'moment'

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';

import Home from './pages/Home'
import Loans from './pages/Loans'
import Credit from './pages/Credit'
import Overdraft from './pages/Overdraft'
import Cuentas from './pages/Cuentas'
import CuentasPersonales from './pages/CuentasPersonales'
import CuentasEmpresariales from './pages/CuentasEmpresariales'
import CuentasMancomunadas from './pages/CuentasMancomunadas'
import CuentasJunior from './pages/CuentasJunior'
import Ahorros from './pages/Ahorros'
import AhorrosMonuu from './pages/AhorrosMonuu'
import AhorrosIntereses from './pages/AhorrosIntereses'
import CobroAnticipado from './pages/CobroAnticipado'
import Ayuda from './pages/Ayuda'
import Login from './pages/Login';
import Download from './pages/Download';
import Nosotros from './pages/Nosotros';
import MonuuMexico from './pages/MonuuMexico';
import MonuuLost from './pages/MonuuLost';
import ComoFunciona from './pages/ComoFunciona';
import Seguridad from './pages/Seguridad';
import Terminos from './pages/Terminos';
import AvisoPrivacidad from './pages/AvisoPrivacidad';
import Cookies from './pages/Cookies';

export default function App () {

    return (
        <div className="app">
            <header>
                <div className="header__container">
                    <Link to="/">
                        <img src="/images/logo-ico-white.png" alt="MONUU"/>
                    </Link>
                    <ul>
                        <li>
                            <Link to="/cuentas">Cuentas <i className="material-icons">keyboard_arrow_down</i></Link>
                            <div className="options">
                                <Link to="/cuentas/personales">Cuentas Personales</Link>
                                {/* <Link to="/cuentas/empresariales">Cuentas Empresariales</Link>
                                <Link to="/cuentas/mancomunadas">Cuentas Mancomunadas</Link> */}
                                <Link to="/cuentas/junior">Cuentas Junior</Link>
                            </div>
                        </li>
                        <li>
                            <Link to="/ahorros">Ahorros <i className="material-icons">keyboard_arrow_down</i></Link>
                            <div className="options">
                                <Link to="/ahorros/monuu">Ahorros MONUU</Link>
                                <Link to="/ahorros/intereses">Ahorros con Intereses</Link>
                            </div>
                        </li>
                        <li>
                            <Link to="/prestamos">Préstamos <i className="material-icons">keyboard_arrow_down</i></Link>
                            <div className="options">
                                <Link to="/prestamos/credito-monuu">Crédito MONUU</Link>
                                <Link to="/prestamos/expres">Exprés</Link>
                            </div>
                        </li>
                        <li>
                            <Link to="/cobro-anticipado">Cobro anticipado</Link>
                        </li>
                        <li>
                            <Link to="/ayuda">Ayuda</Link>
                        </li>
                        <Link to="/iniciar-sesion">Comenzar</Link>
                    </ul>
                </div>
            </header>
            <Switch>
                <Route path="/" component={Home} exact={true} />
                <Route path="/nosotros" component={Nosotros} exact={true} />
                <Route path="/monuu-mexico" component={MonuuMexico} exact={true} />
                <Route path="/monuu-lost" component={MonuuLost} exact={true} />
                <Route path="/como-funciona" component={ComoFunciona} exact={true} />
                <Route path="/seguridad" component={Seguridad} exact={true} />
                <Route path="/terminos-condiciones" component={Terminos} exact={true} />
                <Route path="/aviso-privacidad" component={AvisoPrivacidad} exact={true} />
                <Route path="/politica-cookies" component={Cookies} exact={true} />
                <Route path="/cuentas" component={Cuentas} exact={true} />
                <Route path="/cuentas/personales" component={CuentasPersonales} exact={true} />
                <Route path="/cuentas/empresariales" component={CuentasEmpresariales} exact={true} />
                <Route path="/cuentas/mancomunadas" component={CuentasMancomunadas} exact={true} />
                <Route path="/cuentas/junior" component={CuentasJunior} exact={true} />
                <Route path="/ahorros" component={Ahorros} exact={true} />
                <Route path="/ahorros/monuu" component={AhorrosMonuu} exact={true} />
                <Route path="/ahorros/intereses" component={AhorrosIntereses} exact={true} />
                <Route path="/prestamos" component={Loans} exact={true} />
                <Route path="/prestamos/credito-monuu" component={Credit} exact={true} />
                <Route path="/prestamos/expres" component={Overdraft} exact={true} />
                <Route path="/cobro-anticipado" component={CobroAnticipado} exact={true} />
                <Route path="/ayuda" component={Ayuda} exact={true} />
                <Route path="/iniciar-sesion" component={Login} exact={true} />
                <Route path="/descargar" component={Download} exact={true} />
            </Switch>
            <footer>
                <div className="footer__top">
                    <div className="footer__top-container">
                        <picture>
                            <img src="/images/logo-ico-white.png" alt="MONUU"/>
                        </picture>
                        <ul>
                            <Link to="/nosotros">Nosotros</Link>
                            <Link to="/monuu-mexico">MONUU México</Link>
                            <a target="_blank" href="https://blog.monuu.com">Blog</a>
                            {/* <Link to="/prensa">Prensa</Link> */}
                            <Link to="/bolsa de trabajo">Bolsa de Trabajo</Link>
                            <Link to="/monuu-lost">¿Perdiste tu celular o tarjeta?</Link>
                        </ul>
                        <ul>
                            <a href="https://comunidad.monuu.com">Comunidad</a>
                            {/* <Link to="/proyecto-monuu">Proyecto MONUU</Link> */}
                            {/* <Link to="/legal">Legal</Link> */}
                            <Link to="/como-funciona">¿Cómo funciona?</Link>
                            <Link to="/seguridad">Seguridad</Link>
                            <Link to="/cuentas/empresariales">Cuentas Empresariales</Link>
                        </ul>
                        <ul>
                            <Link to="/ayuda">Preguntas Frecuentes</Link>
                            <Link to="/terminos-condiciones">Términos y Condiciones</Link>
                            <Link to="/aviso-privacidad">Aviso de Privacidad</Link>
                            <Link to="/politica-cookies">Política de Cookies</Link>
                            <Link to="/equipo-trabajo">Equipo de Trabajo</Link>
                        </ul>
                        <div className="footer__social">
                            <h3>Síguenos en todas nuestras redes sociales</h3>
                            <div className="footer__social-list">
                                <IconButton style={{ color: '#fff' }} component="a" href="https://www.facebook.com/Monuubank" target="_blank" rel="noopener noreferrer">
                                    <FacebookIcon style={{ fontSize: 25 }} />
                                </IconButton>
                                <IconButton style={{ color: '#fff' }} component="a" href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                                    <TwitterIcon style={{ fontSize: 25 }} />
                                </IconButton>
                                <IconButton style={{ color: '#fff' }} component="a" href="https://www.instagram.com/monuu_mx/" target="_blank" rel="noopener noreferrer">
                                    <InstagramIcon style={{ fontSize: 25 }} />
                                </IconButton>
                                <IconButton style={{ color: '#fff' }} component="a" href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                                    <LinkedInIcon style={{ fontSize: 25 }} />
                                </IconButton>
                                <IconButton style={{ color: '#fff' }} component="a" href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                                    <YouTubeIcon style={{ fontSize: 25 }} />
                                </IconButton>
                            </div>
                            <p>Alejandro Dumas 71, Polanco IV. Miguel Hidalgo. CP 11550.</p>
                            <span>Tel: 55 1234 5678</span>
                            <p>L a V de 12 a 3pm. Clientes asistencia 24 horas.</p>
                        </div>
                    </div>
                </div>
                <div className="footer__bottom">
                    <div className="footer__bottom-container">
                        <ul>
                            <li>&copy; 2019. Monuu {moment().format('YYYY')}</li>
                            <Link to="/terminos-condiciones">Terminos y Condiciones</Link>
                            <Link to="/aviso-privacidad">Aviso de Privacidad</Link>
                        </ul>
                        <p>MONUU es una aplicación para smartphones mediante la cual se puede administrar la tarjeta Mastercard bajo la marca “MONUU”. Las tarjetas Mastercard bajo la marca “MONUU” son emitidas por Evertec México Servicios de Procesamiento S.A. de C.V. y operadas por Cacao Paycard Solutions S.A.P.I. de C.V., bajo licencia de Mastercard Internacional. La autorización para llevar a cabo operaciones de fondos de pago electrónico a través de la aplicación, será solicitada dentro del plazo indicado por la Ley para Regular las Instituciones de Tecnología Financiera (Ley). En espera de dicha autorización, la operación actual de la aplicación no es supervisada por las Autoridades mexicanas competentes. MONUU PAY SOLUTIONS SA. DE CV. no es un banco ni una entidad financiera, sin embargo, mantiene sus operaciones en términos de la disposición octava transitoria de la Ley para Regular las Instituciones de Tecnología Financiera (Ley).</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}