import React, { useState, useEffect } from 'react'
import { TextField, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const ColorButton = withStyles(theme => ({
    root: {
        color: '#fff',
        backgroundColor: '#EA345C',
        '&:hover': {
            backgroundColor: '#F73660',
        },
        fontSize: '1.4rem'
    },
}))(Button);

export default function MonuuLost () {

    const [email,setemail] = useState('')

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="download">
            <div className="download__container">
                <picture>
                    <img src="/images/mi19.png" alt="Monuu"/>
                </picture>
                <div className="content">
                    <h1>Servicio de emergencias MONUU, escribe tu correo electrónico, escribe el código de confirmación y todos tus servicios serán desactivados parcialmente.</h1>
                    <form onSubmit={e => e.preventDefault() && false}>
                        <TextField
                            label="Email"
                            value={email}
                            variant="outlined"
                            onChange={e => setemail(e.target.value)}
                            style={{ fontSize: '1.4rem' }}
                            fullWidth
                            InputLabelProps={{
                                style: {
                                    fontSize: '1.4rem'
                                }
                            }}
                        />
                        <ColorButton fullWidth style={{ marginLeft: '1rem' }} variant="contained">Recibir código</ColorButton>
                    </form>
                </div>
            </div>
        </div>
    )
}