import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Cuentas () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m2.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Cuentas</h1>
                        <p>Conoce todas las cuentas de MONUU, las mejores comisiones, apertura en menos de 5 minutos, para todo tipo de personas y empresas. Más seguro, más rápido y menos comisiones. La mejor opción para personas y empresas.</p>
                    </div>
                </div>
            </div>
            <div className="cuentas__list">
                <div className="cuentas__list-container">
                    <ul>
                        <li>
                            <h3>Cuentas Personales</h3>
                            <p>Cualquier persona puede crear su cuenta MONUU, regístrate en minutos, abre tu cuenta y recibe tu tarjeta MONUU en la puerta de tu casa. La forma más fácil, rápida y eficiente de entrar en el sistema financiero.</p>
                            <Link to="/cuentas/personales">Ver más <i className="material-icons">keyboard_arrow_right</i></Link>
                        </li>
                        <li>
                            <h3>Cuentas Empresariales</h3>
                            <p>Servicios diseñados para PYMES y grandes empresas, utiliza todas las herramientas financieras que hemos desarrollado para las empresas y descubre los beneficios de utilizar MONUU en tu negocio.</p>
                            <Link to="/cuentas/empresariales">Ver más <i className="material-icons">keyboard_arrow_right</i></Link>
                        </li>
                        <li>
                            <h3>Cuentas Mancomunadas</h3>
                            <p>Crea cuentas mancomunadas, si ya estás registrado en MONUU, puedes crear cuentas mancomunadas en minutos para los fines que necesites y con herramientas de seguridad y reglas de disposición personalizables.</p>
                            <Link to="/cuentas/mancomunadas">Ver más <i className="material-icons">keyboard_arrow_right</i></Link>
                        </li>
                        <li>
                            <h3>Cuentas Junior</h3>
                            <p>Inicia en el mundo financiero con MONUU, con nuestras cuentas MONUU Junior puedes monitorear los gastos y recibir avisos de las cuentas creadas, utilizarlas en cualquier establecimiento y en cualquier parte del mundo.</p>
                            <Link to="/cuentas/junior">Ver más <i className="material-icons">keyboard_arrow_right</i></Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}