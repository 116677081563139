import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Overdraft () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m2.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Crédito Exprés</h1>
                        <p>Recibe hasta $10,000.00 (Diez mil pesos 00/100 mn) en menos de 2 horas en base a los flujos que manejas en tu cuenta.</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Fácil y Rápido</h3>
                        <p>Para realizar la solicitud de crédito únicamente necesitas tener una cuenta MONUU activa.</p>
                    </div>
                    <picture>
                        <img src="/images/mi1.png" alt="Monuu, fácil y rápido"/>
                    </picture>
                </div>
            </div>
            <div className="home__section">
                <div className="home__section-container">
                    <picture>
                        <img src="/images/mi2.png" alt="Monuu, Pre aprobación"/>
                    </picture>
                    <div className="content">
                        <h3>Pre aprobación</h3>
                        <p>Recibe una pre aprobación en base a los flujos que manejas en tu cuenta de MONUU.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}