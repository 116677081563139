import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function CuentasMancomunadas () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m5.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Cuentas Mancomunadas</h1>
                        <p>Entre más mejor, sólo con MONUU, innovamos en el uso de cuentas mancumunadas con un panel de reglas de uso en la cuenta, establece límites por usuario, autorización múltiple o todo lo que tu quieras.</p>
                        <Link to="/descargar">Abre tu cuenta ahora</Link>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Abre tu cuenta mancomunada</h3>
                        <p>Para realizar esta acción las personas involucradas deben tener una cuenta personal MONUU activa. Después de esto, abrir una cuenta mancomunada te tomará tan sólo unos minutos.</p>
                        <Link to="/descargar-app">Descargar App</Link>
                    </div>
                    <picture>
                        <img src="/images/mi6.png" alt="Monuu, Abre tu cuenta mancomunada"/>
                    </picture>
                </div>
            </div>
            <div className="home__section">
                <div className="home__section-container">
                    <picture>
                        <img src="/images/mi2.png" alt="Monuu, Asistencia 24/7"/>
                    </picture>
                    <div className="content">
                        <h3>Asistencia 24/7</h3>
                        <p>Estamos para ayudarte en todo momento, ¿no encuentras las respuestas que necesitas en nuestro panel de ayuda? un ejecutivo estará listo para ayudarte en todo momento</p>
                        <Link to="/ayuda">Panel de Ayuda</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}