import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function CobroAnticipado () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m2.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Cobro Anticipado</h1>
                        <p>Abre la mejor cuenta de débito para personas, sin comisiones, ni montos mínimos</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__image-section">
                <div className="home__image-section-container">
                    <img src="/images/m8.png" alt="Cobro anticipado"/>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <picture style={{ flex: '2' }}>
                        <img src="/images/mi16.png" alt="Monuu, Cobro anticipado"/>
                    </picture>
                    <div className="content">
                        <h3>La comisión más baja</h3>
                        <p>Recibe tu dinero antes y con la comisión más baja del mercado, transfiere tu nómina a MONUU.</p>
                        <h3 style={{ marginTop: '2rem' }}>Al instante</h3>
                        <p>Una vez que tu dinero está disponible tan sólo tienes que entrar a la aplicación, confirmar la solicitud y listo!</p>
                        <h3 style={{ marginTop: '2rem' }}>Más tiempo con tu dinero</h3>
                        <p>Comienza a gastar al instante.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}