import React, { useEffect } from 'react'

export default function Cookies () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="terms">
            <div className="terms__container">
                <h1>Política de cookies</h1>
                <p>Este sitio web, al igual que la mayoría de los sitios en Internet, usa Cookies para mejorar y optimizar la experiencia del usuario. A continuación encontrarás información detallada sobre qué son las “Cookies”, qué tipología utiliza este sitio web, cómo puedes desactivarlas en tu navegador y cómo bloquear específicamente la instalación de Cookies de terceros.</p>
                <h3>¿Qué son las Cookies y cómo las utilizan en Monuu?</h3>
                <p>Las Cookies son archivos que el sitio web o la aplicación que utilizas instala en tu navegador o en tu dispositivo (Smartphone, tableta o televisión conectada) durante tu recorrido por las páginas o por la aplicación, y sirven para almacenar información sobre tu visita. Como la mayoría de los sitios en internet, los portales web de Monuu utilizan Cookies para:</p>
                <ul>
                    <li>Asegurar que las páginas web pueden funcionar correctamente</li>
                    <li>Almacenar tus preferencias, como el idioma que has seleccionado o el tamaño de letra.</li>
                    <li>Conocer tu experiencia de navegación.</li>
                    <li>Recopilar información estadística anónima, como qué páginas has visto o cuánto tiempo has estado en nuestros medios.</li>
                </ul>
                <p>El uso de Cookies nos permite optimizar tu navegación, adaptando la información y los servicios ofrecidos a tus intereses, para proporcionarte una mejor experiencia siempre que nos visites. Los sitios web de Monuu utilizan Cookies para funcionar, adaptar y facilitar al máximo la navegación del Usuario.</p>
                <p>Las Cookies se asocian únicamente a un usuario anónimo y su ordenador/dispositivo y no proporcionan referencias que permitan conocer datos personales. En todo momento podrás acceder a la configuración de tu navegador para modificar y/o bloquear la instalación de las Cookies enviadas por las páginas web de Monuu, sin que ello impida al acceso a los contenidos, salvo por lo que respecta a las cookies que son imprescindibles para la prestación del servicio, sin las cuales la web no puede funcionar. Ten en cuenta en cualquier caso que, si modificas y/o bloqueas la instalación de las Cookies, la calidad del funcionamiento de los Servicios o su disponibilidad pueden verse afectadas.</p>
                <p>Los Usuarios que completen el proceso de registro o hayan iniciado sesión con sus datos de acceso podrán acceder a servicios personalizados y adaptados a sus preferencias según la información personal suministrada en el momento del registro y la almacenada en la Cookie de su navegador.</p>
                <p>Las herramientas de email-marketing de Monuu utilizan pequeñas imágenes invisibles para los usuarios que son incluidas en los emails. Esta tecnología nos permite saber si un email se ha leído o no, en qué fecha, la dirección IP desde la que ha sido consultado, etc. Con esta información, realizamos estudios estadísticos y analíticos sobre el envío y recepción de los emails para mejorar la oferta de los servicios a los que el usuario está suscrito y ofrecerle información que pueda ser de su interés.</p>
                <h3>¿Por qué son importantes?</h3>
                <ul>
                    <li>Desde un punto de vista técnico, permiten que los sitios web funcionen de forma más ágil y adaptada a las preferencias de los usuarios.</li>
                    <li>Sirven para optimizar la publicidad que mostramos a los usuarios, ofreciendo la que más se ajusta a sus intereses.</li>
                </ul>
                <table>
                    <thead>
                        <tr>
                            <th>Tipo de cookie</th>
                            <th>Descripción</th>
                            <th>Ejemplos de uso</th>
                            <th>Servicios de terceros</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Geolocalización</td>
                            <td>Estas Cookies se utilizan para almacenar datos de geolocalización del ordenador o dispositivo para ofrecerte contenidos y servicios más adecuados.</td>
                            <td>Contenidos ajustados al país, ciudad, idioma, etc.</td>
                            <td>Servicios de terceros</td>
                        </tr>
                        <tr>
                            <td>Analítica</td>
                            <td>Estas Cookies recopilan información de tu experiencia de navegación en nuestros portales web de forma totalmente anónima. – Podemos contabilizar el número de visitantes de la página o los contenidos más vistos.</td>
                            <td>Podemos saber si el usuario que está accediendo es nuevo o repite visita, Esa información puede ayudarnos a mejorar la navegación y darte un mejor servicio.</td>
                            <td>Google Analytics, Mix Panel</td>
                        </tr>
                        <tr>
                            <td>Publicidad</td>
                            <td>Estas Cookies recogen información sobre los anuncios mostrados a cada usuario anónimo en los portales web. – Adaptar la publicidad al tipo de dispositivo desde el que el usuario se está conectando.</td>
                            <td>Proporcionar anuncios gestionados, Conocer información sobre el resultado de las campañas como el número de clicks, duración, frecuencia de visualización, etc. Ofrecerte publicidad de interés con base en tus hábitos de navegación.</td>
                            <td>Google Ads, Facebook Ads, Twitter Ads, LinkedIn Ads</td>
                        </tr>
                    </tbody>
                </table>
                <ul>
                    <li>Las de sesión expiran cuando el Usuario abandona la página o cierra el navegador, es decir, están activas mientras dura la visita al sitio web y por tanto son borradas de nuestro ordenador al abandonarlo.</li>
                    <li>Las permanentes expiran cuando se cumple el objetivo para el que sirven o bien cuando se borran manualmente, tienen fecha de borrado y se utilizan normalmente en proceso de compra online, personalizaciones o en el registro, para no tener que introducir nuestra contraseña constantemente. Por otro lado, según quien sea la entidad que gestione el equipo o dominio desde donde se envían las cookies y trate los datos que se obtengan, podemos distinguir entre Cookies propias y de terceros.</li>
                    <li>Las Cookies propias son aquellas Cookies que son enviadas a tu ordenador y gestionadas exclusivamente por nosotros para el mejor funcionamiento del Sitio Web. La información que recabamos se emplea para mejorar la calidad de nuestro servicio y tu experiencia como usuario.</li>
                    <li>Si interactúas con el contenido de nuestro Sitio Web también pueden establecerse cookies de terceros (por ejemplo, al pulsar botones de redes sociales o visionar vídeos alojados en otro sitio web), que son aquellas establecidas por un dominio diferente de nuestro Sitio Web. No podemos acceder a los datos almacenados en las cookies de otros sitios web cuando navegues en los citados sitios web.</li>
                </ul>
                <p>Navegar por este portal web supone que se puedan instalar los siguientes tipos de Cookies:</p>
                <p>Algunos servicios de Monuu pueden utilizar conectores con diversas redes sociales: Facebook, Twitter, etc. Al utilizar el registro social, autorizas a la red social a almacenar una Cookie persistente. Esta Cookie recuerda tu identificación en el servicio, haciendo mucho más rápido el acceso en siguientes visitas. Esta Cookie puede ser eliminada, y además, puedes anular los permisos de acceso de los servicios de Monuu desde la configuración de privacidad de la red social concreta.</p>
                <h3>¿Cómo puedo configurar mis Cookies?</h3>
                <p>Al navegar y continuar en nuestro Sitio Web estará consintiendo el uso de las Cookies en las condiciones contenidas en la presente Política de Cookies. Monuu proporciona acceso a esta Política de Cookies en el momento del registro con el objetivo de que el usuario esté informado, y sin perjuicio de que éste pueda ejercer su derecho a bloquear, eliminar y rechazar el uso de Cookies en todo momento.</p>
                <p>En cualquier caso le informamos de que, dado que las Cookies no son necesarias para el uso de nuestro Sitio Web, puede bloquearlas o deshabilitarlas activando la configuración de su navegador, que le permite rechazar la instalación de todas las cookies o de algunas de ellas. La práctica mayoría de los navegadores permiten advertir de la presencia de Cookies o rechazarlas automáticamente. Si las rechaza podrá seguir usando nuestro Sitio Web, aunque el uso de algunos de sus servicios podrá ser limitado y por tanto su experiencia en nuestro Sitio Web menos satisfactoria.</p>
                {/* AQUI VA LO DE LOS NAVEGADORES */}
                <h3>¿Actualizamos nuestra Política de Cookies?</h3>
                <p>Es posible que actualicemos la Política de Cookies de nuestro Sitio Web, por ello le recomendamos revisar esta política cada vez que acceda a nuestro Sitio Web con el objetivo de estar adecuadamente informado sobre cómo y para qué usamos las cookies.</p>
                <p>La Política de Cookies se actualizó por última vez a fecha 15/08/2019.</p>
                <h3>¿Qué finalidades tienen las cookies que utilizamos para personalizar tu navegación en cuanto a contenidos, servicios y publicidad?</h3>
                <p>Acceso y almacenamiento de datos: Tener acceso a información no sensible de su dispositivo y poder almacenarla, tales como identificadores de publicidad u otros identificadores del dispositivo, incluyendo cookies o tecnologías similares.</p>
                <p>Personalización: – Conocer los contenidos que te interesan y procesarlo para poder personalizar tu experiencia con nosotros y buscar campañas de publicidad o servicios que puedan estar basadas en lo que te interesa.</p>
                <p>Publicidad: Segmentación, resultados y operativa: Registrar tus interacciones con la publicidad con el fin de poder informar de los resultados a los anunciantes y optimizar las campañas en el futuro. Esto incluye poder registrar las campañas que ha visto, con cuales ha interactuado (por ejemplo con un click o terminando en una compra)</p>
                <p>Contenidos: Segmentación, resultados y optimización: Registrar tus interacciones con el contenido con el fin de poder hacer un seguimiento de las propuestas de contenidos o servicios que te hacemos y las mejoras de producto.</p>
                <p>Medición: Recoger la información del uso de contenido y la combinación con la información previa con la finalidad exclusiva de medir y analizar el consumo de contenidos y servicios.</p>
            </div>
        </div>
    )
}