import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import MouseIcon from '@material-ui/icons/Mouse';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AutorenewIcon from '@material-ui/icons/Autorenew';

export default function Ahorros () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m6.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Ahorros</h1>
                        <p>Comienza a ahorrar con MONUU, sin comisiones y con los mejores rendimientos, crea tus propios Pots y organiza tu dinero de la forma que más te convenga</p>
                    </div>
                </div>
            </div>
            <div className="cuentas__highlights">
                <div className="cuentas__highlights-container">
                    <div className="item">
                        <MouseIcon style={{ fontSize: 60, color: '#fff', backgroundColor: '#EA345C', borderRadius: 5, padding: 10 }} />
                        <h4>Ahorra en un click</h4>
                        <p>Transfiere dinero entre tu cuenta y tus pots con un sólo click. Separa tu dinero según lo necesites, realizar abonos y retiros en cualquier momento.</p>
                    </div>
                    <div className="item">
                        <TrendingUpIcon style={{ fontSize: 60, color: '#fff', backgroundColor: '#EA345C', borderRadius: 5, padding: 10 }} />
                        <h4>Obtén rendimiento de tus ahorros</h4>
                        <p>Comienza a ahorrar y obtén los mejores rendimientos del mercado. Establece un plazo de ahorro, comienza a recargar tu cuenta y recibe los rendimientos previstos.</p>
                    </div>
                    <div className="item">
                        <AutorenewIcon style={{ fontSize: 60, color: '#fff', backgroundColor: '#EA345C', borderRadius: 5, padding: 10 }} />
                        <h4>Automatiza tus ahorros</h4>
                        <p>Empieza ahorrando centavos o porcentajes de cada ingreso, puedes poner las reglas y comenzar a automatizar tus ahorros y recibir rendimientos o tenerlos disponibles al instante.</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Ahorros Monuu</h3>
                        <p>Abre tu cuenta en menos de 5 minutos, únicamente necesitas tu celular y recibirás tu tarjeta a la puerta de tu casa.</p>
                        <Link to="/ahorros/monuu">Ver más</Link>
                    </div>
                    <picture>
                        <img src="/images/mi7.png" alt="Monuu, Ahorros Monuu"/>
                    </picture>
                </div>
            </div>
            <div className="home__section">
                <div className="home__section-container">
                    <picture>
                        <img src="/images/mi8.png" alt="Monuu, Ahorros con Intereses"/>
                    </picture>
                    <div className="content">
                        <h3>Ahorros con Intereses</h3>
                        <p>Estamos para ayudarte en todo momento, ¿no encuentras las respuestas que necesitas en nuestro panel de ayuda? un ejecutivo estará listo para ayudarte en todo momento</p>
                        <Link to="/ahorros/intereses">Ver más</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}