import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function MonuuMexico () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m2.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Ayuda</h1>
                        <p>Ya estamos en todo México, con todas las funciones disponibles y listos para evolucionar las finanzas.</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Regístrate</h3>
                        <p>Descarga nuestra aplicación para Android o IOS y comienza a utilizar MONUU en menos de 5 minutos.</p>
                        <Link to="/descargar-app">Descargar App</Link>
                    </div>
                    <picture>
                        <img src="/images/mi18.png" alt="Monuu, Regístrate"/>
                    </picture>
                </div>
            </div>
        </div>
    )
}