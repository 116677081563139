import React, { useEffect, useState } from 'react'
import firebase from '../firebase'

export default function Ayuda () {

    const [help,setHelp] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        firebase.getCollection('help').get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setHelp(data)
        })
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m2.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Ayuda</h1>
                        <p>¿Tienes preguntas? Nosotros te ayudamos, si no encuentras la respuesta contáctanos a nuestro servicio de atención a clientes 24/7</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <ul className="help__list">
                        {
                            help.map(item => <li key={item.id}>
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                            </li>)
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}