import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Credit () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m2.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Crédito Monuu</h1>
                        <p>Realiza tu solicitud de crédito MONUU, recibe una pre aprobación en menos de 24 horas, desde tu aplicación de MONUU.</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Fácil y Rápido</h3>
                        <p>Para comenzar tu solicitud de crédito únicamente necesitas tener una cuenta MONUU activa y comenzar tu solicitud desde la aplicación movil.</p>
                    </div>
                    <picture>
                        <img src="/images/mi1.png" alt="Monuu, fácil y rápido"/>
                    </picture>
                </div>
            </div>
            <div className="home__section">
                <div className="home__section-container">
                    <picture>
                        <img src="/images/mi15.png" alt="Monuu, Obtén tu dinero al instante"/>
                    </picture>
                    <div className="content">
                        <h3>Obtén tu dinero al instante</h3>
                        <p>Una vez que tu solicitud de credito sea aprobada, recibirás una notificación y el dinero estará disponible en tu cuenta al instante.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}