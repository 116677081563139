import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Home () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="home">
            <div className="home__cover">
                <div className="home__cover-container">
                    <div className="content">
                        <h1>Monuu, revolucionando las finanzas en México</h1>
                        <p>Abre tu cuenta en menos de 10 minutos, recibe tu tarjeta de débito y únete a esta evolución. Muy sencillo, menos comisiones, más rápido y mejor atención.</p>
                        <Link to="/descargar">Abrir cuenta MONUU</Link>
                    </div>
                </div>
            </div>
            <div className="home__download">
                <div className="home__download-container">
                    <span>Más de 30,000 personas abrieron su cuenta con MONUU</span>
                    <ul>
                        <a style={{ marginRight: '2rem' }} href="https://monuu.com"><img src="/images/appstore.svg" alt="Descargar Appstore"/></a>
                        <a href="https://monuu.com"><img src="/images/googleplay.svg" alt="Descargar Google Play"/></a>
                    </ul>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Fácil y Rápido</h3>
                        <p>Abre tu cuenta en menos de 5 minutos, únicamente necesitas tu celular y recibirás tu tarjeta a la puerta de tu casa.</p>
                        <Link to="/descargar">Descargar App</Link>
                    </div>
                    <picture>
                        <img src="/images/mi1.png" alt="Monuu, fácil y rápido"/>
                    </picture>
                </div>
            </div>
            <div className="home__section">
                <div className="home__section-container">
                    <picture>
                        <img src="/images/mi2.png" alt="Monuu, Asistencia 24/7"/>
                    </picture>
                    <div className="content">
                        <h3>Asistencia 24/7</h3>
                        <p>Estamos para ayudarte en todo momento, ¿no encuentras las respuestas que necesitas en nuestro panel de ayuda? Un ejecutivo estará listo para ayudarte en todo momento</p>
                        <Link to="/ayuda">Panel de Ayuda</Link>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Seguro</h3>
                        <p>No te vuelvas a preocupar por un cargo no aprobado, innovamos en la seguridad bancaria con prevención de fraude con ayuda de Machine Learning</p>
                        <Link to="/descargar">Descargar App</Link>
                    </div>
                    <picture>
                        <img src="/images/mi3.png" alt="Monuu, Seguro"/>
                    </picture>
                </div>
            </div>
            <div className="home__download">
                <div className="home__download-container">
                    <span>Seguimos mejorando la nueva era de la banca en México, regístrate y forma parte de esta evolución.</span>
                    <ul>
                        <a style={{ marginRight: '2rem' }} href="https://monuu.com"><img src="/images/appstore.svg" alt="Descargar Appstore"/></a>
                        <a href="https://monuu.com"><img src="/images/googleplay.svg" alt="Descargar Google Play"/></a>
                    </ul>
                </div>
            </div>
        </div>
    )
}