import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function ComoFunciona () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m10.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>¿Cómo Funciona?</h1>
                        <p>Muy fácil, mejor que un banco, menos comisiones, más rápido y mejor atención.</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Regístrate</h3>
                        <p>Realiza todas las operaciones desde tu APP MONUU, no necesitas nada más, ¿Tienes problemas? Nosotros te ayudaremos 24/7, si necesitas alguna herramienta extra para tu negocio nosotros te ayudamos a implementarla.</p>
                        <Link to="/descargar-app">Descargar App</Link>
                    </div>
                    <picture>
                        <img src="/images/mi20.png" alt="Monuu, Regístrate"/>
                    </picture>
                </div>
            </div>
            <div className="home__download">
                <div className="home__download-container">
                    <span>Más de 30,000 personas abrieron su cuenta con MONUU</span>
                    <ul>
                        <a style={{ marginRight: '2rem' }} href="https://monuu.com"><img src="/images/appstore.svg" alt="Descargar Appstore"/></a>
                        <a href="https://monuu.com"><img src="/images/googleplay.svg" alt="Descargar Google Play"/></a>
                    </ul>
                </div>
            </div>
        </div>
    )
}