import React, { useState, useEffect } from 'react';
import firebase from '../firebase';
import { TextField, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const ColorButton = withStyles(theme => ({
    root: {
        color: '#fff',
        backgroundColor: '#EA345C',
        '&:hover': {
            backgroundColor: '#F73660',
        },
        fontSize: '1.4rem'
    },
}))(Button);

export default function Download () {

    const [phone,setphone] = useState('')

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="download">
            <div className="download__container">
                <picture>
                    <img src="/images/mi17.png" alt="Monuu"/>
                </picture>
                <div className="content">
                    <h1>¿Aún no tienes MONUU en tu celular? Escribe tu teléfono a continuación y te enviaremos el link de descarga.</h1>
                    <form onSubmit={e => e.preventDefault() && false}>
                        <TextField
                            label="Teléfono"
                            value={phone}
                            variant="outlined"
                            onChange={e => setphone(e.target.value)}
                            style={{ fontSize: '1.4rem' }}
                            fullWidth
                            InputLabelProps={{
                                style: {
                                    fontSize: '1.4rem'
                                }
                            }}
                        />
                        <ColorButton type="submit" onClick={handleSubmit} fullWidth style={{ marginLeft: '1rem' }} variant="contained">Recibir mensaje</ColorButton>
                    </form>
                    <div className="content-apps">
                        <a href="https://www.apple.com/mx/ios/app-store/" target="_blank" rel="noopener noreferrer">
                            <img src="/images/appstore.svg" alt="App Store"/>
                        </a>
                        <a style={{ marginLeft: '1rem' }} href="https://play.google.com/store" target="_blank" rel="noopener noreferrer">
                            <img src="/images/googleplay.svg" alt="App Store"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )

    async function handleSubmit () {
        try {
            if (phone) {
                const item = {
                    phone
                }
                firebase.simpleAdd(item,'phones').then(() => {
                    alert('Cuando estemos listos, te enviaremos un link para descargar la app')
                })
            } else {
                alert('Escribe tu teléfono para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}