import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Nosotros () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m2.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Nosotros</h1>
                        <p>Hemos llegado a revolucionar las finanzas en México, menos comisiones, mejor servicio, mejor tecnnología y más herramientas.</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Fácil y Rápido</h3>
                        <p>Abre tu cuenta en menos de 5 minutos, únicamente necesitas tu celular y recibirás tu tarjeta a la puerta de tu casa.</p>
                        <Link to="/descargar-app">Descargar App</Link>
                    </div>
                    <picture>
                        <img src="/images/mi1.png" alt="Monuu, fácil y rápido"/>
                    </picture>
                </div>
            </div>
            <div className="home__section">
                <div className="home__section-container">
                    <picture>
                        <img src="/images/mi2.png" alt="Monuu, Asistencia 24/7"/>
                    </picture>
                    <div className="content">
                        <h3>Asistencia 24/7</h3>
                        <p>Estamos para ayudarte en todo momento, ¿no encuentras las respuestas que necesitas en nuestro panel de ayuda? un ejecutivo estará listo para ayudarte en todo momento</p>
                        <Link to="/ayuda">Panel de Ayuda</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}