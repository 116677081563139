import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function AhorrosIntereses () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m2.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Ahorros con Intereses</h1>
                        <p>Comienza a ahorrar con MONUU, recibiendo los mejores intereses del mercado y comienza a automatizar tus ahorros con proyecciones de tus rendimientos.</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Proyecciones</h3>
                        <p>Crea proyecciones de los rendimientos que recibirás de tus ahorros en base al tiempo y las recargas que realizarás.</p>
                    </div>
                    <picture>
                        <img src="/images/mi11.png" alt="Monuu, Proyecciones"/>
                    </picture>
                </div>
            </div>
            <div className="home__section">
                <div className="home__section-container">
                    <picture>
                        <img src="/images/mi12.png" alt="Monuu, Crea tu cuenta y conoce nuestros fondos"/>
                    </picture>
                    <div className="content">
                        <h3>Crea tu cuenta y conoce nuestros fondos</h3>
                        <p>Conoce nuestros fondos y los intereses que ofrece cada uno, conoce por qué somos tu mejor opción para obtener los mejores rendimientos.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}