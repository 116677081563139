import React, { useEffect } from 'react'

export default function Terminos () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="terms">
            <div className="terms__container">
                <h1>Términos y Condiciones</h1>
                <p>Declara Monuu Pay Solutions, Sociedad Anónima Promotora de Inversión de Capital Variable, en lo sucesivo “Monuu”, con domicilio en Darwin 32, Colonia Anzures, Delegación Miguel Hidalgo, CDMX, 11900. Los presentes Términos y Condiciones de Uso se encuentran disponibles a través del sitio web www.Monuu.com y la aplicación móvil Monuu, disponible para los sistemas operativos iOS y Android.</p>
                <p>Estos Términos y Condiciones regulan el uso de la aplicación “Monuu" así como todas aquellas operaciones relacionadas con el uso de dicha aplicación. Estos términos constituyen un acuerdo de voluntades entre Monuu Pay Solutions S.A.P.I. de C.V. (a quien en adelante nos podremos referir como “Monuu”, “nosotros”, "nuestro") y usted (a quien en adelante nos podremos referir como “Usuario”, “Cliente”, “usted”, “suyo”, en plural o singular).</p>
                <p>La operación de Monuu se encuentra cubierta en términos de la disposición octava transitoria de la Ley para Regular las Instituciones de Tecnología Financiera, en lo sucesivo la “Ley”. En virtud de lo anterior, la autorización para operar como institución financiera regulada y llevar a cabo operaciones dentro de esta plataforma de fondos de pago electrónico, se solicitará dentro del plazo indicado por dicha Ley. La operación de la plataforma, de acuerdo con el artículo octavo transitorio de la Ley, se encuentra dentro del periodo que transcurra entre la entrada en vigor de la Ley y el momento en que Monuu obtenga la autorización para operar como Institución de Tecnología Financiera (ITF).</p>
                <p>Declara el Usuario ser una persona mayor de edad y contar con capacidad para contratar y contraer toda clase de obligaciones. Asimismo declara que entiende plenamente el contenido de los presentes Términos y Condiciones. Si alguna parte de los presentes Términos y Condiciones no le parecen aceptables o no le son comprensibles en su totalidad, requerimos que el Usuario suspenda el uso de la aplicación Monuu. El acceso y uso de la aplicación Monuu implican la aceptación de los Términos y Condiciones expresados en el presente acuerdo.</p>
                <p>Para los efectos del presente acuerdo, las partes acuerdan que por Usuario se entenderá: cualquier persona física o moral que ingrese, acceda y/o use la aplicación (incluyendo cualquiera de los elementos que despliegan su contenido) y/o a la persona física o moral que se registre y/o use, cualquiera de los servicios que se ofrecen a través de dicha aplicación.</p>
                <p>Durante el uso de la aplicación el Usuario manifestará expresamente su consentimiento sobre estos Términos y Condiciones a través del uso de medios electrónicos al darse de alta en la aplicación Monuu y llevará a cabo una o múltiples acciones afirmativas que impliquen una interacción activa del Usuario con la Aplicación y de la cual se permita derivar la manifestación de su consentimiento expreso. Asimismo, declara expresamente su aceptación utilizando para tal efecto medios electrónicos para la manifestación de su voluntad, en términos de lo dispuesto por Código Civil para el Distrito Federal; el Código de Comercio y demás legislación aplicable.</p>
                <p>La sola utilización de la aplicación Monuu implica la aceptación plena e incondicional de todas y cada una de las condiciones incluidas en estos Términos y Condiciones en la versión publicada por Monuu en el momento mismo que el Usuario accede a la aplicación.</p>
                <p>Cualquier modificación a los presentes Términos de Uso será realizada cuando Monuu lo considere apropiado, sin tener la obligación de notificarlo previamente al usuario. Monuu podrá notificar con treinta días de anticipación, sin tener la obligación de hacerlo, acerca de cualquier modificación a los presentes términos siendo exclusiva responsabilidad del Usuario asegurarse de tomar conocimiento de tales modificaciones. Cualquier versión subsecuente de estos Términos y Condiciones se entenderá que suple a todas las versiones previas.</p>
                <h2>Alcance del servicio y uso de la aplicación</h2>
                <p>Monuu presenta una interfaz para que el Usuario pueda acceder a su cuenta digital. La cuenta digital permite recibir y enviar transferencias electrónicas así como acceder a un medio de disposición para realizar compras a través de terminales punto de venta y disposiciones en cajeros automáticos. Al ingresar a esta aplicación, el Usuario reconoce que Monuu le está otorgando una licencia de uso temporal y revocable en los términos que a continuación se describen.</p>
                <p>A través de la aplicación Monuu, los Usuarios registran datos personales y de debida diligencia para tener un adecuado conocimiento del Usuario. El usuario declara que la información que proporcione, declare o manifieste; respecto de sí mismo; sus movimientos; el origen de sus recursos o cualquier otro tipo de información relevante; al momento de abrir su cuenta y en todo momento de su interacción con Monuu será veráz y podrá ser verificada en cualquier momento cuando Monuu o cualquiera de sus socios comerciales así lo determinen. El Usuario reconoce y acepta que Monuu se reserva el derecho de validar la información proporcionada, directamente o a través terceros, para corroborar la veracidad y validez de la misma.</p>
                <p>Asimismo, el Usuario acepta y conviene que únicamente puede abrir UNA cuenta por usuario ligado a sus datos de identidad. El usuario, al abrir la cuenta, manifiesta que será quien la controle y no permitirá el acceso a la misma a ningún tercero.</p>
                <p>A través del uso de la aplicación Monuu, el Usuario puede administrar su cuenta, obtener información acerca de sus movimientos y realizar operaciones tales como fondeos, retiros, transferencias, disposiciones así como consultar información detallada acerca de sus hábitos de consumo, para lo cual el Usuario manifiesta su consentimiento con la elaboración de reportes personalizados para el análisis de sus hábitos de consumo. Asimismo, dentro de la aplicación, el usuario puede manifestar de manera expresa su consentimiento (opt - in) para recibir notificaciones acerca de sus eventos de consumo y sobre su grado de avance hacia sus metas u objetivos que mantenga en cuentas segregadas. El Usuario puede consultar la información relativa a la tarjeta y al número CLABE que se le ha asignado asociados a su cuenta.</p>
                <p>Las tarjetas Mastercard bajo la marca Monuu son emitidas tanto por Evertec México Servicios de Procesamiento SA de CV y operada por Cacao Paycard Solutions SAPI de CV, bajo licencia de Mastercard Internacional. Monuu es una aplicación para smartphones mediante la cual se puede administrar la cuenta y la tarjeta Mastercard asociada a la misma bajo la marca Monuu.</p>
                <h2>Límites y plazos de fondeo</h2>
                <p>El Usuario puede recibir fondos en su cuenta mediante transferencias electrónicas de fondos SPEI o fondeos en Tiendas de conveniencia y otras cadenas comerciales. El Usuario podrá disponer de los fondos en su cuenta a través de transferencias electrónicas, pagos a través de terminales punto de venta y disposiciones en cajeros automáticos.</p>
                <p>Las transferencias electrónicas de fondos a través de SPEI pueden tomar hasta 48 horas en acreditarse y los fondeos a través de tiendas de conveniencia u otros comercios afiliados pueden tardar hasta 72 horas en acreditarse para verse reflejados en la aplicación.</p>
                <p>Asimismo, el Usuario acepta y reconoce que la cuenta asociada a la aplicación Monuu está sujeta a los siguientes límites transaccionales:</p>
                <ul>
                    <li>Límite por operación mediante transferencia electrónica de fondos a través del sistema SPEI: $15,000 MXN.</li>
                    <li>
                        Límite de retiro en cajeros automáticos:
                        <ul>
                            <li>$9,000 (Nueve mil) pesos M.N. diarios.</li>
                            <li>$6,000 (Seis mil) pesos M.N. por transacción.</li>
                            <li>Máximo se puede realizar un total de 9 retiros al día.</li>
                            <li>Independientemente del límite de retiro establecido en este inciso, cada retiro está sujeto al límite de disposición de cada cajero automático.</li>
                        </ul>
                    </li>
                    <li>
                        Límite total acumulable en la cuenta asociada a la tarjeta Monuu:
                        <ul>
                            <li>$10,000 (Diez mil) dólares de los Estados Unidos de América (USD).</li>
                        </ul>
                    </li>
                </ul>
                <h2>Comisiones y uso de la aplicación</h2>
                <p>El Usuario podrá transferir dinero en los 365 días del año sin restricción de horarios. Para transferencias entre tarjetas Monuu no habrá costo alguno. Las transferencias Monuu - Monuu y hacia otros bancos son totalmente sin costo. Monuu cobra comisiones por los siguientes conceptos y en los montos indicados a continuación:</p>

                <table>
                    <thead>
                        <tr>
                            <th>Concepto</th>
                            <th>Comisión (MXN)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Uso de tarjeta Monuu Mastercard</td>
                            <td>$0.00</td>
                        </tr>
                        <tr>
                            <td>Realizar transferencias electrónicas vía SPEI </td>
                            <td>$4.00</td>
                        </tr>
                        <tr>
                            <td>Realizar transferencias entre usuarios de Monuu</td>
                            <td>$0.00</td>
                        </tr>
                        <tr>
                            <td>Tener una cuenta digital</td>
                            <td>$0.00</td>
                        </tr>
                        <tr>
                            <td>Mantenimiento de la tarjeta</td>
                            <td>$0.00</td>
                        </tr>
                        <tr>
                            <td>Generación de estado de cuenta</td>
                            <td>$0.00</td>
                        </tr>
                        <tr>
                            <td>Inactividad</td>
                            <td>$70.00</td>
                        </tr>
                        <tr>
                            <td>Depósito en comercio</td>
                            <td>$0.00</td>
                        </tr>
                        <tr>
                            <td>Retiro en cajero ATM</td>
                            <td>$0.00</td>
                        </tr>
                        <tr>
                            <td>Pago de servicios</td>
                            <td>$10.00</td>
                        </tr>
                        <tr>
                            <td>Reemplazo de tarjeta (por causas atribuibles al usuario)</td>
                            <td>$150.00</td>
                        </tr>
                    </tbody>
                </table>
                <p>Las comisiones que se generen por fondeos en cadenas comerciales y retiros en Cajeros Automáticos son aplicadas por el propio comercio o Cajero Automático. Monuu no obtiene ninguna participación, beneficio o utilidad de las comisiones que cobran dichos establecimientos.</p>
                <p>Si el usuario requiriera un reemplazo de tarjeta, este deberá cubrir una Cuota por Reemplazo de tarjeta de $150 pesos M.N., más el impuesto al valor agregado correspondiente o cualquier otro impuesto aplicable, dicha cuota se deducirá mediante compensación sobre los fondos disponibles en su cuenta, en caso de que el usuario no cuente con fondos disponibles, no se enviará una reposición de la tarjeta hasta en tanto no haya fondeado la cantidad necesaria para cubrir la Cuota por Reemplazo una vez que el Usuario haya solicitado y autorizado el reemplazo para que se envíe la tarjeta al domicilio acordado.</p>
                <p>Si el reemplazo de tarjeta se debe a fallos atribuibles a Monuu, la Cuota por Reemplazo será cubierta por Monuu y no por el Usuario.</p>
                <h2>Pago de servicios</h2>
                <p>El Usuario podrá pagar a proveedores de distintos servicios como energía eléctrica, telefonía, internet, entre otros que defina Monuu, desde la aplicación. Por este servicio se cobrará una cuota de $8.62 pesos M.N. más el impuesto al valor agregado correspondiente o cualquier otro impuesto aplicable, por cada servicio pagado mediante la aplicación Monuu.</p>
                <h2>Modificaciones a la Aplicación</h2>
                <p>Monuu podrá en cualquier momento y cuando lo considere conveniente, sin necesidad de previo aviso al Usuario, realizar correcciones, adiciones, mejoras o modificaciones al contenido, presentación, información, servicios, áreas, bases de datos y demás elementos de la aplicación sin que ello dé lugar ni derecho a ninguna reclamación o indemnización, y sin que dichas modificaciones impliquen reconocimiento ni el surgimiento de responsabilidad alguna en favor del Usuario.</p>
                <p>En los casos en los cuales el servicio que presta Monuu a los usuarios sufra una afectación o cambio mayor, Monuu, sin tener la obligación de hacerlo, podrá notificar, con treinta días de anticipación, cualquier modificación a la aplicación.</p>
                <p>El Usuario, durante este plazo de treinta días de anticipación a cualquier modificación a la aplicación, podrá aceptar explícitamente los nuevos términos o cualquier modificación a la aplicación. El uso continuado de la aplicación posterior a este plazo implicará la aceptación tácita por parte del Usuario. En caso de que el usuario no acepte dichos términos o modificaciones, así lo notifique expresamente a Monuu dentro del plazo de 30 días posteriores a la notificación del cambio, se procederá a dar de baja su cuenta y se llevará a cabo el procedimiento de cancelación de su cuenta, conforme a lo dispuesto en los presentes Términos y Condiciones de Uso.</p>
                <h2>Cancelación y cierre de la cuenta</h2>
                <p>El Usuario puede, en el momento que lo desee, dar por terminada la relación establecida en el presente acuerdo y, por ende, cancelar su cuenta Monuu. Monuu le garantiza al Usuario que eliminará toda la información del Usuario contenida en su cuenta de manera permanente dejando a salvo las obligaciones contenidas en el artículo 58 de la Ley, lo dispuesto en la Ley Federal de Prevención de Operaciones con Recursos de Procedencia Ilícita, en su caso, y las obligaciones en la materia respecto de Protección de Datos Personales en Posesión de los Particulares, conforme al proceso establecido en el ejercicio de los Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición) definido en nuestro Aviso de Privacidad.</p>
                <p>El Usuario puede dar por terminado el presente acuerdo en cualquier momento contactando al área de Atención a Usuarios, conforme a lo descrito en la siguiente sección; todos los recursos que se encuentren en su cuenta al momento de la cancelación deberán ser retirados a través de transferencia o de cualquiera de los medios de disposición descritos en el presente acuerdo.</p>
                <p>Monuu se reserva el derecho de suspender, cancelar o modificar la cuenta de cualquier usuario sin previo aviso, sin que ello derive en responsabilidad alguna para Monuu. Monuu se reserva el derecho de restringir o cancelar el acceso a su cuenta en aquellos casos en que, a juicio exclusivamente de Monuu, a) el usuario haya de cualquier manera infringido los presentes Términos y Condiciones b) Monuu reciba cualquier requerimiento por parte de alguna autoridad que así lo requiera c) se tenga una sospecha razonable de que el Usuario ha actuado en contravención de la política de prevención de operaciones con recursos de procedencia ilícita d) el usuario realice fondeos o haga uso indebido de la cuenta, realice actividades fraudulentas, actos ilícitos comprobables o bajo sospechas razonables de ilicitud o prácticas fraudulentas e) el usuario proporcione información falsa o engañosa acerca de su identidad; f) por cualquier motivo en el que las operaciones que realice el Usuario representen un riesgo para Monuu; g) en caso de que transcurran más de 45 días naturales sin transacciones y no existan fondos disponibles en la tarjeta.</p>
                <h2>Atención a Usuarios</h2>
                <p>Para el fin descrito en la sección precedente o para cualquiera de los fines que el Usuario considere necesarios puede contactar al área de Atención a Usuarios a través de los siguientes mecanismos:</p>
                <ul>
                    <li>Usando el chat disponible en la aplicación Monuu.</li>
                    <li>Usando el chat disponible en la plataforma web en el apartado de Centro de Ayuda.</li>
                    <li>Llamando al teléfono </li>
                    <li>Enviando un correo electrónico a la siguiente dirección: info@monuu.com</li>
                </ul>
                <p>El área de Atención a Usuarios será la encargada de atender asuntos relacionados con aclaraciones de su cuenta, presentar cualquier tipo de reclamación, cancelaciones, consulta de estados de cuenta, reactivación de cuentas, consultas acerca de los presentes Términos y Condiciones de Uso y cualquier tema relacionado con la terminación del presente acuerdo.</p>
                <h2>Notificaciones</h2>
                <p>Monuu tomará como medio de contacto principal el correo electrónico proporcionado por el Usuario así como las notificaciones que se envían al dispositivo móvil del Usuario a través de la aplicación Monuu.</p>
                <p>Es responsabilidad del Usuario mantener esta información actualizada y cerciorarse de que la información proveniente de Monuu no está siendo desviada por ningún filtro que impida que el Usuario sea notificado. Monuu llevará a cabo todas las notificaciones que considere pertinentes a través del medio de contacto principal. Dichas notificaciones pueden contener información relacionada con el estatus de la cuenta, modificaciones a los presentes Términos y Condiciones o del Aviso de Privacidad.</p>
                <p>Asimismo, todos los movimientos que realiza el Usuario, como fondeos mediante transferencia electrónica de fondos SPEI o a través de un pago referenciado en tiendas de conveniencia o cadenas comerciales, se alertarán a través de la aplicación Monuu. La información sobre las transferencias incluye datos relativos al emisor y de datos de identificación del Usuario o Cliente beneficiario.</p>
                <p>El Usuario será notificado a través de cualquiera de los medios de contacto oficiales en caso de que se presente cualquier falla en la plataforma, en la aplicación o con las redes de pago. Dichas fallas pueden ser o no atribuibles a Monuu, pero en ningún caso darán derecho al Usuario a exigir indemnización por parte de Monuu o atribuirle responsabilidad alguna.</p>
                <p>Monuu únicamente llamará o contactará directamente a un Usuario para la activación de la tarjeta del usuario, seguimiento a aclaraciones que haya iniciado el Usuario, o alertas de fraude detectadas en la cuenta, por teléfono, al número registrado en la cuenta. En ninguna de estas instancias, Monuu solicitará al Usuario que otorgue información personal y/o confidencial por teléfono. Toda la información del usuario deberá ser enviada por medios oficiales - correo o chat de soporte en el app - a Atención a clientes. Monuu no contacta a usuarios o personas para ofrecerles la contratación de cualquier tipo de servicio propio o de terceros; tampoco para requerir dinero ni para obtener o corroborar Datos Personales. En el entendido de que existen varias estafas que utilizan la red para realizar transacciones ilícitas como: Smishing, Phishing, Pharming, es responsabilidad del usuario abstenerse de proporcionar cualquier dato personal o recursos de cualquier naturaleza a aquellas personas que aparenten o se hagan pasar por agentes de Monuu y ser víctima de estos delitos.</p>
                <h2>Modificaciones al Convenio</h2>
                <p>Monuu se reserva el derecho de modificar los Términos y Condiciones de éste Convenio en cualquier tiempo, siendo efectivas dichas modificaciones de forma inmediata, conforme a lo descrito en la primera sección de los presentes Términos y Condiciones.</p>
                <p>Ocasionalmente, Monuu podrá incluir provisiones relativas a áreas específicas o nuevos servicios que se proporcionen en o a través de la aplicación ("Términos Adicionales"), los cuales serán publicados en las áreas específicas o nuevos servicios de la aplicación para su lectura y aceptación. El Usuario reconoce y acepta que dichos Términos Adicionales formarán parte integrante del presente Convenio para todos los efectos legales a que haya lugar.</p>
                <p>En particular, Monuu no garantiza que los Usuarios utilicen la aplicación, los servicios y los contenidos de conformidad con estas condiciones generales y, en su caso, las condiciones particulares aplicables, ni que lo hagan de forma diligente y prudente. La aplicación Monuu tampoco tiene la obligación de verificar y no verifica la veracidad, vigencia, exhaustividad y/o autenticidad de los datos que los Usuarios proporcionan sobre sí mismos a otros Usuarios o a cualquier tercero.</p>
                <p>La aplicación y Monuu no asumen responsabilidad alguna por los daños y perjuicios de cualquier naturaleza que pudieran derivarse de la utilización por parte de los Usuarios de los servicios y contenidos de la aplicación Monuu. Ni de aquellos que puedan derivarse de la falta de veracidad, vigencia, exhaustividad y/o autenticidad de la información que los Usuarios proporcionaron a otros Usuarios o a cualquier tercero acerca de sí mismos y, en particular, aunque no de forma exclusiva, por los daños y perjuicios de toda naturaleza que puedan derivarse de la suplantación de la personalidad de un tercero efectuada por un Usuario en la aplicación.</p>
                <p>Bajo ninguna circunstancia Monuu será responsable de cualquier daño y/o perjuicio, directo o indirecto, causado en virtud de la confianza del Usuario en información obtenida a través de esta aplicación. Monuu se reserva el derecho de suprimir o modificar el contenido de la aplicación que, a exclusivo juicio de Monuu, no cumpla con los estándares o que pudiera resultar contrario al ordenamiento jurídico vigente. Pero Monuu no será responsable por cualquier falla o retraso que se genere al eliminar tal material.</p>
                <h2>Reglas de Uso</h2>
                <p>La información publicada en la aplicación no necesariamente refleja la opinión de Monuu ni de sus empleados, oficiales, directores, accionistas, licenciatarios y concesionarios. Por esta razón, Monuu no se hace responsable por ninguna información, contenidos y conceptos que se emitan en la aplicación. Asimismo, Monuu no se hace responsable de la información contenida en la aplicación, incluidas los elementos que se descarguen, en el entendido de que es bajo el propio riesgo y responsabilidad del Usuario el uso y seguimiento de la misma.</p>
                <p>Monuu se reserva el derecho de bloquear el acceso al Usuario o remover en forma parcial o total, toda información, comunicación o material que, a su exclusivo juicio, pueda resultar: (i) abusivo, difamatorio, obsceno, ofensivo (ii) fraudulento, artificioso o engañoso, (iii) violatorio de derechos de autor, marcas, confidencialidad, secretos industriales o cualquier derecho de propiedad intelectual de un tercero, o (iv) de cualquier forma contravenga lo establecido en estos Términos y Condiciones.</p>
                <h2>Inactividad</h2>
                <p>En caso que el usuarios incurra en inactividad, es decir, más de 90 días naturales sin transacciones y con saldo en su tarjeta, Monuu cobrará al usuario una cuota de inactividad de $70.00 pesos M.N. mensuales mientras exista saldo en la tarjeta, en caso de que el saldo se agotara, se procederá a la cancelación.</p>
                <h2>Derechos de Autor y Propiedad Industrial</h2>
                <p>Monuu y la aplicación Monuu, sus logotipos y todo el material que aparece en el sitio y aplicación, son marcas registradas, nombres de dominio y/o nombres comerciales propiedad de sus respectivos titulares protegidos por los tratados internacionales y leyes aplicables en materia de propiedad intelectual y derechos de autor.</p>
                <p>Los derechos de autor sobre el contenido, organización, recopilación, compilación, información, logotipos, programas, aplicaciones, o en general cualquier información contenida o publicada en la aplicación se encuentran debidamente protegidos a favor de Monuu, sus Afiliados, Proveedores y/o de sus respectivos propietarios, de conformidad con la legislación aplicable en materia de propiedad intelectual.</p>
                <p>Asimismo, las marcas, avisos comerciales, nombres comerciales, diseños, con las que se anuncien o promuevan los servicios de las instituciones financieras y/o bancarias anunciadas en la aplicación, son propiedad y responsabilidad de cada institución y solamente se presentan en la aplicación para la identidad y referencia de cada institución financiera y/o bancaria, que es la titular absoluta de los derechos de Propiedad Intelectual.</p>
                <p>Se prohíbe expresamente al usuario modificar, alterar o suprimir, ya sea en forma total o parcial, los avisos, marcas, nombres comerciales, señas, anuncios, logotipos o en general cualquier indicación que se refiera a la propiedad de información contenida en la aplicación.</p>
                <p>En caso de que el Usuario transmita a Monuu cualquier información, programas, aplicaciones, software o en general cualquier material que requiera ser licenciado a través de la aplicación, el Usuario le otorga a Monuu en este acto, una licencia perpetua, universal, gratuita, no exclusiva, mundial y libre de regalías, incluyendo entre los derechos otorgados, el derecho de sublicenciar, vender, reproducir, distribuir, transmitir, crear trabajos derivados, exhibirlos y ejecutarlos públicamente.</p>
                <p>Lo establecido en el inciso anterior se aplicará igualmente a cualquier otra información que el Usuario envíe o transmita a Monuu (incluyendo, sin limitación alguna, ideas para renovar o mejorar la aplicación Monuu), sea que éstas hayan sido incluidas en cualquier espacio de la aplicación en virtud de otros medios o modos de transmisión ya conocidos o en el futuro desarrollados.</p>
                <p>Por lo anterior, el Usuario renuncia expresamente en este acto a intentar cualquier acción, demanda o reclamación en contra de Monuu, sus Afiliados o Proveedores por cualquier actual o eventual violación de cualquier derecho de autor o propiedad intelectual derivado de la información, programas, aplicaciones, software, ideas y demás material que el propio Usuario envíe sobre la aplicación.</p>
                <p>El Usuario está de acuerdo que el uso de la aplicación se realiza bajo su propio riesgo, que los servicios y productos que se prestan y ofrecen se prevén sobre una base “tal cual” y “según sean disponibles”. Monuu no garantiza que la aplicación satisfaga la totalidad de los requerimientos del Usuario, o que los servicios de la aplicación se mantengan siempre ininterrumpidos, en tiempo, seguros o libres de errores.</p>
                <h2>Indemnización</h2>
                <p>El Usuario está de acuerdo en indemnizar a Monuu, sus Afiliados, Proveedores, Vendedores y Asesores de cualquier acción, demanda o reclamación (incluso de honorarios de abogados y de costas judiciales) derivadas de cualquier incumplimiento por parte del Usuario al presente convenio. El Usuario entiende que la operación de Monuu se encuentra cubierta en términos de la disposición octava transitoria de la Ley para Regular las Instituciones de Tecnología Financiera (Ley). Monuu solicitará la autorización para operar como institución financiera regulada y llevar a cabo operaciones dentro de esta plataforma de fondos de pago electrónico, dentro del plazo indicado por dicha Ley por lo que operación de la plataforma en el periodo que transcurra entre la entrada en vigor la Ley y el momento en que se obtenga la autorización no es una actividad supervisada por las autoridades correspondientes, el Usuario libera a Monuu de cualquier responsabilidad y le otorga el más amplio finiquito.</p>
                <h2>Legislación Aplicable y Jurisdicción </h2>
                <p>En caso de que se presente una controversia que se derive del presente contrato o se relacione con él, las partes se establecen como jurisdicción aplicable los Tribunales de la Ciudad de México, renunciando a cualquier otro fuero que pudiera corresponderles, quedando este Convenio a la interpretación de acuerdo con las leyes aplicables de los Estados Unidos Mexicanos.</p>
            </div>
        </div>
    )
}