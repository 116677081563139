import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import TimerIcon from '@material-ui/icons/Timer';

export default function AhorrosMonuu () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m6.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Ahorros Monuu</h1>
                        <p>Ahorra con un click, distribuye tu dinero como más te convenga y realiza movimientos al instante en todo momento</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="cuentas__highlights">
                <div className="cuentas__highlights-container">
                    <div className="item">
                        <MonetizationOnIcon style={{ fontSize: 60, color: '#fff', backgroundColor: '#EA345C', borderRadius: 5, padding: 10 }} />
                        <h4>Redondea</h4>
                        <p>La mejor forma de ahorrar, de todos tus gastos puedes rendondear tus centavos y pasarlos a cualquiera de tus pots.</p>
                    </div>
                    <div className="item">
                        <AutorenewIcon style={{ fontSize: 60, color: '#fff', backgroundColor: '#EA345C', borderRadius: 5, padding: 10 }} />
                        <h4>Automatiza</h4>
                        <p>Establece un porcentaje de cada ingreso o establece tiempos en los que te gustaría que se recargaran tus pots, personalizalos y fija tus objetivos, todo desde la aplicación.</p>
                    </div>
                    <div className="item">
                        <TimerIcon style={{ fontSize: 60, color: '#fff', backgroundColor: '#EA345C', borderRadius: 5, padding: 10 }} />
                        <h4>Al Instante</h4>
                        <p>Recarga y realiza retiros en todo momento, no importa la hora ni el día y al instante podrás disponer de tu dinero guardado en los pots.</p>
                    </div>
                </div>
            </div>
            <div className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>En una misma cuenta</h3>
                        <p>Todo tu dinero en una misma cuenta pero separado, tu dinero estará separado de tu balance general pero todo estará en la misma cuenta.</p>
                    </div>
                    <picture>
                        <img src="/images/mi10.png" alt="Monuu, En una misma cuenta"/>
                    </picture>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <picture>
                        <img src="/images/mi9.png" alt="Monuu, Automatiza"/>
                    </picture>
                    <div className="content">
                        <h3>Automatiza</h3>
                        <p>Crea tus propias reglas para automatizar tus ahorros, en base a porcentajes, tiempo, automatizar cada ingreso que recibes en tu cuenta y de muchas formas más.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}