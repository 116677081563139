import React, { useEffect } from 'react'

export default function AvisoPrivacidad () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="terms">
            <div className="terms__container">
                <h1>Política de Privacidad</h1>
                <p>Monuu Pay Solutions S.A.P.I. de C.V., en lo sucesivo “Monuu”, está comprometida a proteger su privacidad. Además de lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de Particulares, en lo sucesivo la “Ley” y su reglamento, trabajamos bajo las mejores prácticas internacionales en el manejo y administración de datos personales. Por lo que constantemente, manejaremos sus datos personales con altos estándares de ética, responsabilidad y profesionalismo.</p>
                <p>Este aviso de privacidad es aplicable específicamente a la información que recopilamos de usted a través de nuestra aplicación denominada Monuu.</p>
                <p>En términos de la Ley, en nuestra calidad de responsables del tratamiento de sus datos personales, le informamos lo siguiente:</p>
                <ol>
                    <li>
                        A través de esta aplicación, recopilamos únicamente los datos que a continuación se señalan:
                        <ol type="a">
                            <li>
                                Datos Personales y Financieros
                                <ul>
                                    <li>Nombre completo, género, lugar y fecha de nacimiento, correo electrónico, domicilio y número celular.</li>
                                    <li>Información financiera concerniente al tipo de cuenta bancaria, ingresos y egresos.</li>
                                </ul>
                            </li>
                            <li>
                                Datos de Uso
                                <ul>
                                    <li>Navegación, uso de características, clicks, visitas, conversiones.</li>
                                </ul>
                            </li>
                        </ol>
                        Usted se compromete a que los datos que proporciona a Monuu Pay Solutions sean verídicos, completos y exactos. Cualquier dato falso, incompleto o inexacto al momento de recabarlos, será de su exclusiva responsabilidad, en caso de que requiera rectificar alguno de ellos, le ofrecemos que lo haga directamente a través de su cuenta de Usuario o bien al correo electrónico: info@monuu.com
                    </li>
                    <li>
                        Recopilamos la información señalada en el punto anterior con los siguientes propósitos:
                        <ol type="a">
                            <li>Fines de identificación y verificación de datos</li>
                            <li>Fines estadísticos</li>
                            <li>Para informarle acerca de las actualizaciones de la aplicación, así como enviarle información importante relativa a su cuenta de usuario.</li>
                            <li>Para proporcionarle una mejor experiencia de uso de nuestra aplicación, al conocer sus hábitos de consumo y sus correspondientes datos de ingresos y egresos, y así ofrecerle asesoría financiera más precisa.</li>
                        </ol>
                    </li>
                    <li>En la recolección de datos personales seguimos todos los principios que marca la Ley, en su Artículo 6, tales como Licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y responsabilidad.</li>
                    <li>En ningún caso se venderán, regalarán, facilitarán ni alquilarán sus datos personales y/o financieros a terceros, salvo su expresa autorización, a excepción de los socios comerciales y/o subsidiarias y filiales involucrados en la operación de nuestros sistemas, o cuando por medio de una orden judicial se requiera para cumplir con ciertas disposiciones procesales. Sólo se podrá difundir la información en casos especiales, como identificar, localizar o realizar acciones legales contra aquellas personas que infrinjan las condiciones de servicio de la aplicación, causen daños a, o interfieran en, los derechos de Monuu, sus propiedades, de otros Usuarios del portal o de cualquier persona que pudiese resultar perjudicada por dichas acciones.</li>
                    <li>La seguridad y la confidencialidad de los datos que los usuarios proporcionen al contratar un servicio o comprar un producto en línea estarán protegidos por un servidor seguro bajo el protocolo Secure Socket Layer (SSL), de tal forma que los datos enviados se transmitirán encriptados para asegurar su resguardo.</li>
                    <li>El Usuario otorga su consentimiento a Monuu, para que le envíe todo tipo de publicidad, incluyendo promociones. El Usuario podrá dejar de recibir los mensajes de promoción y publicidad enviando un correo a la dirección info@Monuu.com,  pidiendo que se le elimine de la lista de correos para envío de publicidad.</li>
                    <li>
                        Las cookies son archivos de texto que son descargados automáticamente y almacenados en el disco duro del equipo de cómputo del Usuario al navegar en una página de internet específica, que permiten recordar al servidor de Internet algunos datos sobre los usuarios que acceden al portal electrónico www.Monuu.com.<br/>
                        En Monuu usamos las cookies para:
                        <ol type="a">
                            <li>Su tipo de navegador y sistema operativo.</li>
                            <li>Las páginas de internet que visita.</li>
                            <li>Los vínculos que sigue.</li>
                        </ol>
                        Estas cookies y otras tecnologías pueden ser deshabilitadas. Para conocer cómo hacerlo, consulte la información de su explorador de Internet.
                    </li>
                    <li>Este aviso de privacidad cumple con los requisitos que marca la Ley. Para Monuu la seguridad de sus datos es muy importante, por lo que contamos con mecanismos tecnológicos, físicos, administrativos y legales para proteger su información, tales como servidores con los más altos niveles de seguridad informática y el certificado SSL de conexión segura. Además nuestras bases de datos y toda la información que viaja a través de ellas se encuentra bajo encriptación de 128 bits (el mismo estándar que utilizan los sitios bancarios) y debidamente resguardada en servidores que cuentan con más de 7 niveles de seguridad tanto física como informática.</li>
                    <li>Monuu, se reserva el derecho de cambiar este aviso de privacidad en cualquier momento, mediando la debida notificación que exige la ley. En caso de que exista algún cambio en este aviso de privacidad, Monuu lo comunicará de la siguiente manera: (a) enviándole un correo electrónico a la cuenta que ha registrado en la aplicación y/o (b) publicando una nota visible en nuestra aplicación. Monuu, no será responsable si usted no recibe la notificación de cambio en el aviso de privacidad si existiere algún problema con su cuenta de correo electrónico o de transmisión de datos por internet. Por su seguridad, revise en todo momento que así lo desee el contenido de este aviso de privacidad en nuestro portal www.monuu.mx/privacidad</li>
                    <li>
                        Usted podrá ejercer sus derechos ARCO (acceso, rectificación, cancelación y/u oposición) a partir del 1 de julio de 2019:
                        <ul>
                            <li>Domicilio: Paseo De Los Tamarindos #90, Bosques de las lomas, Cuidad de México 05120</li>
                            <li>Correo Electrónico: info@Monuu.com</li>
                            <li>Teléfono: +52 55-2559-2767</li>
                        </ul>
                        La solicitud ARCO deberá contener y acompañar lo que señala la Ley en su artículo 29 y el 89, 90, 92 y demás aplicables de su Reglamento.
                    </li>
                    <li>En caso de que se presente una controversia que se derive del presente Aviso de privacidad, las partes intentarán primero resolverla a través de negociaciones de buena fe, pudiendo ser asistidos por un mediador profesional. Si después de un máximo de 30 días de mediación las partes no llegaren a un acuerdo, se estará a lo dispuesto por la Ley, aceptando las partes la intervención que pudiere tener el Instituto Federal de Acceso a la Información y Protección de Datos Personales.</li>
                    <li>Al hacer uso de la aplicación Monuu, usted renuncia a cualquier otro fuero y legislación que le pudiere corresponder. Este portal y sus servicios están regidos por las leyes mexicanas, y cualquier controversia será resuelta frente a las autoridades mexicanas competentes.</li>
                    <li>Este aviso de privacidad queda aceptado en el momento de registrarse en nuestra aplicación Monuu y nuestro sitio web www.monuu.com</li>
                </ol>
            </div>
        </div>
    )
}