import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Loans () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m7.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Préstamos</h1>
                        <p>Obtén un préstamo exprés hasta por $10,000.00 (Diez mil pesos 00/100 mn) en menos de dos horas o hasta $500,000.00 (Quinientos mil pesos 00/100 mn)con un Crédito MONUU</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Crédito MONUU</h3>
                        <p>Obtén un crédito MONUU con aprobación en menos de 24 horas por hasta $500,000.00 (Quinientos mil pesos 00/100 mn). Comienza tu solicitud de crédito desde la aplicación.</p>
                        <Link to="/prestamos/credito-monuu">Ver más</Link>
                    </div>
                    <picture>
                        <img src="/images/mi13.png" alt="Monuu, Crédito MONUU"/>
                    </picture>
                </div>
            </div>
            <div className="home__section">
                <div className="home__section-container">
                    <picture>
                        <img src="/images/mi14.png" alt="Monuu, Crédito exprés"/>
                    </picture>
                    <div className="content">
                        <h3>Crédito exprés</h3>
                        <p>Obtén un crédito en menos de dos horas en base al promedio del flujo que hay en tu cuenta, recibe una pre aprobación en menos de una hora, únicamente para clientes MONUU.</p>
                        <Link to="/prestamos/expres">Ver más</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}