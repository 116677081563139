import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { TextField, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';

const ColorButton = withStyles(theme => ({
    root: {
        color: '#fff',
        backgroundColor: '#EA345C',
        '&:hover': {
            backgroundColor: '#F73660',
        },
        fontSize: '1.4rem'
    },
}))(Button);

export default function Login () {

    const [email,setemail] = useState('')
    const [password,setpassword] = useState('')

    return (
        <div className="login">
            <div className="login__container">
                <h1>Inicia Sesión</h1>
                <form onSubmit={e => e.preventDefault() && false}>
                    <TextField
                        label="Email"
                        value={email}
                        variant="outlined"
                        fullWidth
                        onChange={e => setemail(e.target.value)}
                        style={{ marginBottom: '2rem', fontSize: '1.4rem' }}
                        InputLabelProps={{
                            style: {
                                fontSize: '1.4rem'
                            }
                        }}
                    />
                    <TextField
                        label="Contraseña"
                        value={password}
                        variant="outlined"
                        fullWidth
                        type="password"
                        onChange={e => setpassword(e.target.value)}
                        style={{ marginBottom: '2rem', fontSize: '1.4rem' }}
                        InputLabelProps={{
                            style: {
                                fontSize: '1.4rem'
                            }
                        }}
                    />
                    <ColorButton size="large" style={{ marginBottom: '2rem' }} fullWidth variant="contained">Iniciar Sesión</ColorButton>
                </form>
                <p>¿No tienes cuenta? <Link to="/descargar">Regístrate</Link></p>
            </div>
        </div>
    )
}