import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function CuentasEmpresariales () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m4.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Cuentas Empresariales</h1>
                        <p>Resolvemos problemas reales que tienen las empresas en México, las mejores herramientas para empresas e información agil, clara y oportuna.</p>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Fácil y Rápido</h3>
                        <p>Abre tu cuenta en menos de 5 minutos, únicamente necesitas tu celular y recibirás tu tarjeta a la puerta de tu casa.</p>
                        <Link to="/descargar-app">Descargar App</Link>
                    </div>
                    <picture>
                        <img src="/images/mi1.png" alt="Monuu, fácil y rápido"/>
                    </picture>
                </div>
            </div>
            <div className="home__section">
                <div className="home__section-container">
                    <picture>
                        <img src="/images/mi4.png" alt="Monuu, Asistencia 24/7"/>
                    </picture>
                    <div className="content">
                        <h3>Asistencia 24/7</h3>
                        <p>Estamos para ayudarte en todo momento, ¿no encuentras las respuestas que necesitas en nuestro panel de ayuda? un ejecutivo estará listo para ayudarte en todo momento.</p>
                        <Link to="/ayuda">Panel de Ayuda</Link>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Agrega personas a tu cuenta empresarial</h3>
                        <p>Agrega a todas las personas de tu negocios involucradas en tu cuenta, asigna roles, permisos y límites para cada uno, recibe notificaciones de cada acción y autoriza movimientos según lo necesites.</p>
                        <Link to="/descargar-app">Descargar App</Link>
                    </div>
                    <picture>
                        <img src="/images/mi5.png" alt="Monuu, Agrega personas a tu cuenta empresarial"/>
                    </picture>
                </div>
            </div>

            <p>*** Aquí agregar formulario para dar acceso anticipado, ver con David que datos vamos a solicitar a empresas ***</p>
        </div>
    )
}