import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import HistoryIcon from '@material-ui/icons/History';
import SecurityIcon from '@material-ui/icons/Security';

export default function CuentasPersonales () {

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    return (
        <div className="cuentas">
            <div style={{ backgroundImage: 'url(/images/m3.png)' }} className="cover">
                <div className="cover__container">
                    <div className="content">
                        <h1>Cuentas Personales</h1>
                        <p>Abre tu cuenta en menos de 5 minutos, únicamente necesitas tu identificación oficial, comprobante de domicilio y listo! Descubre la evolución de las finanzas personales con MONUU.</p>
                        <Link to="/descargar">Abrir cuenta MONUU</Link>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="cuentas__highlights">
                <div className="cuentas__highlights-container">
                    <div className="item">
                        <AccountBalanceWalletIcon style={{ fontSize: 60, color: '#fff', backgroundColor: '#EA345C', borderRadius: 5, padding: 10 }} />
                        <h4>Préstamo exprés</h4>
                        <p>¿Necesitas dinero rápido? Nosotros te prestamos en menos de 2 horas, en base al flujo de tu cuenta obtén hasta $10,000.00 (Diez mil pesos 00/100 mn) en menos de dos horas y con la tasa más baja.</p>
                    </div>
                    <div className="item">
                        <HistoryIcon style={{ fontSize: 60, color: '#fff', backgroundColor: '#EA345C', borderRadius: 5, padding: 10 }} />
                        <h4>Pagos y transferencias</h4>
                        <p>Realiza pagos y transferencias las 24 horas del día, tu cuenta MONUU nunca te va a fallar, envía y recibe dinero a cualquier cuenta de banco en México.</p>
                    </div>
                    <div className="item">
                        <SecurityIcon style={{ fontSize: 60, color: '#fff', backgroundColor: '#EA345C', borderRadius: 5, padding: 10 }} />
                        <h4>Seguridad</h4>
                        <p>La mejor tecnología y seguridad para realizar tus cobros y pagos, utlizamos Machine Learning para prevenir fraudes y optimizar el uso de tu tarjeta.</p>
                    </div>
                </div>
            </div>
            <div className="home__section">
                <div className="home__section-container">
                    <div className="content">
                        <h3>Fácil y Rápido</h3>
                        <p>Abre tu cuenta en menos de 5 minutos, únicamente necesitas tu celular y recibirás tu tarjeta a la puerta de tu casa.</p>
                        <Link to="/descargar-app">Descargar App</Link>
                    </div>
                    <picture>
                        <img src="/images/mi1.png" alt="Monuu, fácil y rápido"/>
                    </picture>
                </div>
            </div>
            <div style={{ backgroundColor: '#fff' }} className="home__section">
                <div className="home__section-container">
                    <picture>
                        <img src="/images/mi2.png" alt="Monuu, Asistencia 24/7"/>
                    </picture>
                    <div className="content">
                        <h3>Asistencia 24/7</h3>
                        <p>Estamos para ayudarte en todo momento, ¿no encuentras las respuestas que necesitas en nuestro panel de ayuda? un ejecutivo estará listo para ayudarte en todo momento</p>
                        <Link to="/ayuda">Panel de Ayuda</Link>
                    </div>
                </div>
            </div>
            <div className="home__download">
                <div className="home__download-container">
                    <span>Abre tu cuenta desde la aplicación en minutos</span>
                    <ul>
                        <a style={{ marginRight: '2rem' }} href="https://monuu.com"><img src="/images/appstore.svg" alt="Descargar Appstore"/></a>
                        <a href="https://monuu.com"><img src="/images/googleplay.svg" alt="Descargar Google Play"/></a>
                    </ul>
                </div>
            </div>
        </div>
    )
}